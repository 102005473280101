<template>
  
  <div>
    


    <b-table striped hover :items="items"></b-table>
  </div>
</template>

<script>
  
  export default {
    data() {
      return {
        items: [
          //{ folio: 40, departamento: 'Catastro', vehiculo: 'NWA3592', persona: 'Sergio Rios Cruz', litros:'30', estatus:'Cargado'},
          //{ folio: 41, departamento: 'Ecología', vehiculo: 'TCG9347', persona: 'Angel Ivan Lopez Alvarez', litros:'50', estatus:'Cancelado'},
          //{ folio: 42, departamento: 'Obras Públicas', vehiculo: 'AAUN7729', persona: 'Bianca Cristina Garrido Alvarez', litros:'20', estatus:'Cargado a la mitad'},
          //{ folio: 43, departamento: 'Servicios Públicos', vehiculo: 'VNY3109', persona: 'Camilo Lopez Fajardo', litros:'45', estatus:'Sin cargar'},
          //{ folio: 44, departamento: 'Deportes', vehiculo: 'POL9957', persona: 'Sebastian Marquez Salazar', litros:'30', estatus:'Vencido'},
          {folio: 40, estatus:'Cargado', fecha_emision:'25/02/23', fecha_carga:'25/02/23', numero_factura:'28628', productos:'otros',marca: 'Ford', submarca: 'Figo', modelo: '2018', color: 'Rojo', serie:'ckjnj78374hbhhx', numero_economico:'003', placas: 'NWA3592', n_inventario: '02324', n_litros: '50', nombre: 'Sergio', apellido_paterno: 'Rios', apellido_materno: 'Cruz', rfc: 'LOAA9503115H1', ID_registro_proveedor: '001', ubicacion: 'CARRETRA MEXICO TULANCIGO KM 32', proveedor: 'PEMEX MEXICO - TULANCINGO', ID_registro_dependencia: '001', dependencia_general: 'Catastro', dependencia_auxiliar: 'Cobranza', responsable: 'Ivan Lopez Alvarez', ID_registro_centro: '001', identificador_centro: '1090', gasolina_regular: '$22', gasolina_premium:'$25', diesel: '$23', fecha:'17/01/2024'},
          {folio: 41, estatus:'Vencido', fecha_emision:'25/02/23', fecha_carga:'25/02/23', numero_factura:'28628', productos:'otros',marca: 'Ford', submarca: 'Figo', modelo: '2018', color: 'Rojo', serie:'ckjnj78374hbhhx', numero_economico:'003', placas: 'NWA3592', n_inventario: '02324', n_litros: '50', nombre: 'Sergio', apellido_paterno: 'Rios', apellido_materno: 'Cruz', rfc: 'LOAA9503115H1', ID_registro_proveedor: '001', ubicacion: 'CARRETRA MEXICO TULANCIGO KM 32', proveedor: 'PEMEX MEXICO - TULANCINGO', ID_registro_dependencia: '001', dependencia_general: 'Catastro', dependencia_auxiliar: 'Cobranza', responsable: 'Ivan Lopez Alvarez', ID_registro_centro: '001', identificador_centro: '1090', gasolina_regular: '$22', gasolina_premium:'$25', diesel: '$23', fecha:'17/01/2024'},
          {folio: 42, estatus:'Cancelado', fecha_emision:'25/02/23', fecha_carga:'25/02/23', numero_factura:'28628', productos:'otros',marca: 'Ford', submarca: 'Figo', modelo: '2018', color: 'Rojo', serie:'ckjnj78374hbhhx', numero_economico:'003', placas: 'NWA3592', n_inventario: '02324', n_litros: '50', nombre: 'Sergio', apellido_paterno: 'Rios', apellido_materno: 'Cruz', rfc: 'LOAA9503115H1', ID_registro_proveedor: '001', ubicacion: 'CARRETRA MEXICO TULANCIGO KM 32', proveedor: 'PEMEX MEXICO - TULANCINGO', ID_registro_dependencia: '001', dependencia_general: 'Catastro', dependencia_auxiliar: 'Cobranza', responsable: 'Ivan Lopez Alvarez', ID_registro_centro: '001', identificador_centro: '1090', gasolina_regular: '$22', gasolina_premium:'$25', diesel: '$23', fecha:'17/01/2024'},  
          {folio: 43, estatus:'Cargado a medias', fecha_emision:'25/02/23', fecha_carga:'25/02/23', numero_factura:'28628', productos:'otros',marca: 'Ford', submarca: 'Figo', modelo: '2018', color: 'Rojo', serie:'ckjnj78374hbhhx', numero_economico:'003', placas: 'NWA3592', n_inventario: '02324', n_litros: '50', nombre: 'Sergio', apellido_paterno: 'Rios', apellido_materno: 'Cruz', rfc: 'LOAA9503115H1', ID_registro_proveedor: '001', ubicacion: 'CARRETRA MEXICO TULANCIGO KM 32', proveedor: 'PEMEX MEXICO - TULANCINGO', ID_registro_dependencia: '001', dependencia_general: 'Catastro', dependencia_auxiliar: 'Cobranza', responsable: 'Ivan Lopez Alvarez', ID_registro_centro: '001', identificador_centro: '1090', gasolina_regular: '$22', gasolina_premium:'$25', diesel: '$23', fecha:'17/01/2024'},  
          {folio: 44, estatus:'Generado', fecha_emision:'25/02/23', fecha_carga:'25/02/23', numero_factura:'28628', productos:'otros',marca: 'Ford', submarca: 'Figo', modelo: '2018', color: 'Rojo', serie:'ckjnj78374hbhhx', numero_economico:'003', placas: 'NWA3592', n_inventario: '02324', n_litros: '50', nombre: 'Sergio', apellido_paterno: 'Rios', apellido_materno: 'Cruz', rfc: 'LOAA9503115H1', ID_registro_proveedor: '001', ubicacion: 'CARRETRA MEXICO TULANCIGO KM 32', proveedor: 'PEMEX MEXICO - TULANCINGO', ID_registro_dependencia: '001', dependencia_general: 'Catastro', dependencia_auxiliar: 'Cobranza', responsable: 'Ivan Lopez Alvarez', ID_registro_centro: '001', identificador_centro: '1090', gasolina_regular: '$22', gasolina_premium:'$25', diesel: '$23', fecha:'17/01/2024'}  
        
        
        ],
        value: '',
        context: null,

        methods: {
      onContext(ctx) {
        this.context = ctx
      }
    }
      }
    }
  }
</script>
